import { problemAwareHttp } from '@/http';

export class CustomDomain {
  /**
   *
   * @param domain {String}
   */
  constructor(domain) {
    this.domain = domain;
  }
}

export default class CustomDomainService {
  /**
   *
   * @param deploymentId {String}
   * @returns {Promise<AxiosResponse<CustomDomain[]>>}
   */
  static async getCustomDomains(deploymentId) {
    return problemAwareHttp.get(`/deployments/${deploymentId}/custom-domains/`).then((result) => result.data);
  }

  /**
   *
   * @param deploymentId {String}
   * @param domain {String}
   * @returns {Promise<AxiosResponse<any>>}
   */
  static register(deploymentId, domain) {
    return problemAwareHttp.put(`/deployments/${deploymentId}/custom-domains/${domain}`).then((response) => {
      if (response.headers['x-tracking-url']) {
        window.open(response.headers['x-tracking-url']);
      }
      return response;
    });
  }

  /**
   *
   * @param deploymentId {String}
   * @param domain {String}
   * @returns {Promise<AxiosResponse<any>>}
   */
  static unregister(deploymentId, domain) {
    return problemAwareHttp.delete(`/deployments/${deploymentId}/custom-domains/${domain}`).then((response) => {
      if (response.headers['x-tracking-url']) {
        window.open(response.headers['x-tracking-url']);
      }
      return response;
    });
  }
}
