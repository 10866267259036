<template>
  <ciam-card-content-line type="full-width">
    <template v-slot:content>
      <ciam-card>
        <div>
          <Promised :promise="loading">
            <template v-slot:pending>
              <ciam-loader class="py-4"></ciam-loader>
            </template>

            <template v-slot:rejected="problem">
              <ciam-alert
                v-if="(problem.type = 'not-available-on-current-plan')"
                :title="problem.title"
                :description="getUpsellAlertDescription()"
                :type="AlertStatus.WARNING_HREF"
                :href-text="$t('upsell.email.contact')"
                :href="getUpsellAlertHref()"
              />
            </template>

            <template>
              <ciam-card-content-line type="full-width">
                <template v-slot:content>
                  <b-table style="clear: both" :striped="true" :hoverable="true" :data="allInstances">
                    <b-table-column field="fullName" :label="$t('deploymentInstances.instances.fullName')"
                                    v-slot="props">
                      <ciam-clipboard title="Copy instance Id" :payload="props.row.id">{{ props.row.fullName }}
                      </ciam-clipboard>
                      <ciam-text class="ml-4 tag is-success " v-if="props.row.isLive">
                        {{ $t('deploymentInstances.instances.isLive') }}
                      </ciam-text>

                    </b-table-column>
                    <b-table-column field="provider" :label="$t('deploymentInstances.instances.provider')"
                                    v-slot="props">
                      <ciam-text>{{ props.row.provider }}</ciam-text>
                    </b-table-column>
                    <b-table-column field="region" :label="$t('deploymentInstances.instances.region')" v-slot="props">
                      <ciam-text>{{ props.row.region }}</ciam-text>
                    </b-table-column>
                    <b-table-column field="region" :label="$t('deploymentInstances.instances.keycloakVersion')"
                                    v-slot="props">
                      <ciam-text>{{ props.row.keycloakVersion }}</ciam-text>
                    </b-table-column>
                    <b-table-column field="region" :label="$t('deploymentInstances.instances.branch')"
                                    v-slot="props">
                      <ciam-text>{{ props.row.branch }}</ciam-text>
                    </b-table-column>
                    <b-table-column field="status" :label="$t('deploymentInstances.instances.status')" v-slot="props">
                      <div class="flex flex-row gap-2 justify-stretch items-center">
                        <status-badge :status="props.row.status" />
                        <b-tooltip :label="$t('deploymentInstances.tooltip.status')" type="is-info" position="is-top">
                          <ciam-button class="mr-4 h-8 w-8"
                                       @click="statusModal.isOpened = true, currentInstance = { id: props.row.id, status: props.row.status, name: props.row.fullName }">
                            <ciam-icon name="fa-edit" />
                          </ciam-button>
                        </b-tooltip>
                      </div>
                    </b-table-column>
                    <b-table-column field="live" v-slot="props">
                      <b-tooltip :label="$t('deploymentInstances.tooltip.promote')" type="is-info" position="is-top">
                        <ciam-button :disabled="props.row.isLive || props.row.status === 'DELETING'" class="mr-4 h-8 w-8"
                                     @click="promoteInstanceLive(props.row.id)">
                          <ciam-icon name="fa-play" />
                        </ciam-button>
                      </b-tooltip>
                      <b-tooltip :label="$t('deploymentInstances.tooltip.redeploy')" type="is-info" position="is-top">
                        <ciam-button class="mr-4 h-8 w-8"
                                     @click="redeployModal.isOpened = true, currentInstance = { id: props.row.id }">
                          <ciam-icon name="fa-sync-alt" />
                        </ciam-button>
                      </b-tooltip>
                      <b-tooltip :label="$t('deploymentInstances.tooltip.delete')" type="is-info" position="is-top">
                        <ciam-button :disabled="props.row.isLive || props.row.status === 'DELETING'" class="h-8 w-8" @click="deleteInstanceConfirmationModal.isOpened = true, currentInstance = { id: props.row.id, name: props.row.fullName }">
                          <ciam-icon class="text-red-500" name="fa-trash" />
                        </ciam-button>
                      </b-tooltip>
                    </b-table-column>

                    <template #empty>
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>{{ $t('deployment.deploymentInstances.emptyList') }}</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </template>
              </ciam-card-content-line>
            </template>
          </Promised>
        </div>
      </ciam-card>
      <b-modal
        :active.sync="redeployModal.isOpened"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
      >
        <standard-modal
          :buttons="redeployModal.buttons"
          :item="currentInstance"
          @result="redeployInstance()"
        >
          <template v-slot:header>{{ $t('deploymentInstances.redeploy.header') }}</template>
          <template v-slot:default>
            <div>
              <ciam-select
                :options="redeployActions"
                v-model="redeployMode"
              ></ciam-select>
            </div>
          </template>
        </standard-modal>
      </b-modal>
      <b-modal
        :active.sync="statusModal.isOpened"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
      >
        <standard-modal
          :buttons="statusModal.buttons"
          :item="currentInstance"
          @result="setInstanceStatus()"
        >
          <template v-slot:header>{{ $t('deploymentInstances.status.header') }}</template>
          <template v-slot:default>
            <div>
              <ciam-select
                :options="status"
                v-model="currentInstance.status"
              ></ciam-select>
            </div>
          </template>
        </standard-modal>
      </b-modal>
      <b-modal
        :active.sync="deleteInstanceConfirmationModal.isOpened"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
      >
        <standard-modal
          :buttons="deleteInstanceConfirmationModal.buttons"
          :item="currentInstance"
          @result="deleteInstance()"
        >
          <template v-slot:header>{{ $t('deleteInstanceConfirmationModal.confirmRemoval') }}</template>
          <template>
            <p>
              <b>{{ $t('deleteInstanceConfirmationModal.confirmRemovalQuestion') }}</b>
            </p>
            <hr />
            <p>{{ $t('deleteInstanceConfirmationModal.confirmRemovalQuestionBis') }}</p>
            <hr />
            <p style="padding-top: 30px">
              {{ $t('deleteInstanceConfirmationModal.name') }}: {{ currentInstance.name }}
              <ciam-input
                type="text"
                v-model="deleteInstanceConfirmationModal.nameConfirmation"
                :placeholder="currentInstance.name"
              />
            </p>
          </template>
        </standard-modal>
      </b-modal>
    </template>
  </ciam-card-content-line>

</template>

<script>
import DeploymentService from '@/pages/deployments/DeploymentService';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert.vue';
import { makeQueryablePromise } from '@/util/promise';
import { Notification } from 'vue-notifyjs';
import CiamClipboard from '@/components/CiamClipboard.vue';
import CiamSelect from '@/components/CiamSelect.vue';
import StandardModal from '@/components/StandardModal.vue';
import CiamInput from '@/components/CiamInput.vue';
import StatusBadge from '@/components/StatusBadge.vue';

export default {
  name: 'DeploymentConfigurations',
  components: { StatusBadge, CiamInput, StandardModal, CiamSelect, CiamClipboard, CiamAlert },
  data() {
    return {
      problem: null,
      loading: makeQueryablePromise(Promise.reject()),
      AlertStatus: AlertStatus,
      deployment: null,
      standbyInstances: [],
      liveInstance: null,
      allInstances: null,
      redeployMode: 'complete',
      currentInstance: {},
      deleteInstanceConfirmationModal: {
        isOpened: false,
        nameConfirmation: '',
        buttons: [
          { text: this.$t('actions.cancel') },
          {
            text: this.$t('actions.delete'),
            classes: ['is-danger', 'is-outlined'],
            returnVal: true,
          },
        ],
      },
      redeployModal: {
        isOpened: false,
        buttons: [
          { text: this.$t('actions.cancel') },
          {
            text: this.$t('deploymentInstances.redeploy.action'),
            classes: ['is-info', 'is-outlined'],
            returnVal: true,
          },
        ],
      },
      statusModal: {
        isOpened: false,
        buttons: [
          { text: this.$t('actions.cancel') },
          {
            text: this.$t('deploymentInstances.status.action'),
            classes: ['is-info', 'is-outlined'],
            returnVal: true,
          },
        ],
      }
    };
  },
  mounted() {
    this.getInstances();
  },
  methods: {
    getInstances() {
      this.loading = DeploymentService.getInstances(this.$route.params.id)
        .then(instances => {
          // Store details of standby instances
          const standbyPromises = instances.standbyInstanceIds.map(instanceId => {
            return DeploymentService.getInstance(instanceId)
              .then(instanceDetails => {
                return instanceDetails;
              })
              .catch(reason => {
                console.error('Failed to retrieve details of standby instance ', instanceId, ': ', reason);
                return null;
              });
          });

          // Store details of live instance
          const liveInstancePromise = DeploymentService.getInstance(instances.liveInstanceId)
            .then(instanceDetails => {
              instanceDetails.isLive = true;
              return instanceDetails;
            })
            .catch(reason => {
              console.error('Failed to retrieve details of live instance: ', reason);
              return null;
            });

          // Wait for all promises to resolve
          return Promise.all([liveInstancePromise, ...standbyPromises])
            .then(([liveInstance, ...standbyInstanceDetailsArray]) => {
              // Store details of live instance and standby instances in the same array
              this.allInstances = [liveInstance, ...standbyInstanceDetailsArray];
            });
        })
        .catch(reason => {
          console.error('Instances could not be retrieved: ', reason);
          throw reason;
        });
    },
    promoteInstanceLive(instanceId) {
      DeploymentService.promoteInstanceLive(instanceId)
        .then(res => {
          Notification.notify({
            type: 'info',
            message: this.$t('deploymentInstances.instances.successToPromote'),
          });
          this.getInstances();
        })
        .catch(reason => {
          Notification.notify({
            type: 'danger',
            message: this.$t('deploymentInstances.instances.failedToPromote'),
          });
          return null;
        });
    },
    setInstanceStatus() {
      DeploymentService.setInstanceStatus(this.currentInstance.id, this.currentInstance.status)
        .then(res => {
          Notification.notify({
            type: 'info',
            message: this.$t('deploymentInstances.instances.successToSetStatus'),
          });
          this.getInstances();
        })
        .catch(reason => {
          Notification.notify({
            type: 'danger',
            message: this.$t('deploymentInstances.instances.failedToSetStatus'),
          });
          return null;
        });
    },
    redeployInstance() {
      DeploymentService.redeployInstance(this.currentInstance.id, this.redeployMode)
        .then(res => {
          Notification.notify({
            type: 'info',
            message: this.$t('deploymentInstances.redeploy.success'),
          });
          this.getInstances();
        })
        .catch(reason => {
          Notification.notify({
            type: 'danger',
            message: this.$t('deploymentInstances.redeploy.failure'),
          });
          return null;
        });
    },
    deleteInstance() {
      if (this.deleteInstanceConfirmationModal.nameConfirmation !== this.currentInstance.name) {
        alert(this.$t('deleteDeploymentConfirmationModal.mismatch'));
        this.deleteInstanceConfirmationModal.nameConfirmation = '';
        return false;
      }
      DeploymentService.deleteInstance(this.currentInstance.id)
        .then(res => {
          Notification.notify({
            type: 'info',
            message: this.$t('deploymentInstances.instances.successToDelete'),
          });
          this.getInstances();
        })
        .catch(reason => {
          Notification.notify({
            type: 'danger',
            message: this.$t('deploymentInstances.instances.failedToDelete'),
          });
          return null;
        });
    },
  },
  computed: {
    redeployActions() {
      return this.$t(`redeployActions`);
    },
    status() {
      return this.$t(`status`);
    },
  },
};
</script>

