<template>
  <div>
    <ciam-card :bg="'gray'" v-if="$keycloak.hasRealmRole('ADMIN')">
      <ciam-card-content-lines>
        <ciam-card-content-line type="full-width">
          <template v-slot:content>
            <ciam-card>
              <ciam-card-header>
                <template v-slot:header>
                  <ciam-text>{{ $t('deployment.advanced.information') }}</ciam-text>
                </template>
              </ciam-card-header>
              <ciam-card-content-lines>
                <ciam-card-content-line v-if="deployment && deployment._links.credentials">
                  <template v-slot:label>{{ $t('deploymentDetail.credentials') }}</template>
                  <template v-slot:content>
                    <ciam-button v-if="credentialsUser === null" @click="reveal()">
                      <ciam-icon name="fa-eye"></ciam-icon>
                    </ciam-button>
                    <div v-else>
                      u: <ciam-clipboard title="copy username" class="ml-2 mr-2 credentials" :payload="credentialsUser">{{ credentialsUser }}</ciam-clipboard>
                      p: <ciam-clipboard title="copy password" :payload="credentialsPassword" class="credentials">{{ credentialsPassword | truncate(5)}}</ciam-clipboard>
                    </div>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line>
                  <template v-slot:label>
                    {{ $t('deployment.advanced.monitoring') }}
                  </template>
                  <template v-slot:content>
                    <div class="flex flex-col gap-2">
                      <ciam-link target="_blank" :href="linkToDashboardMetrics()">Show metrics</ciam-link>
                      <ciam-link target="_blank" :href="linkToDashboardLogs()">Show logs</ciam-link>
                      <ciam-link target="_blank" :href="linkToDashboardAccessLogs()">Show access logs</ciam-link>
                    </div>
                  </template>
                </ciam-card-content-line>

                <ciam-card-content-line v-if="deployment && topology">
                  <template v-slot:label>
                    {{ $t('deployment.advanced.topology') }}
                  </template>
                  <template v-slot:content>
                    <div class="flex flex-col gap-2">
                      <div class="flex flex-row gap-8">
                        <div class="flex flex-col gap-2">
                          <ciam-text class="bold"> {{ $t('deployment.advanced.loadBalancers') }}</ciam-text>
                          <div class="flex flex-col gap-2">
                            <div v-for="lb in topology.loadBalancers" :key="lb.publicIp">
                              <ciam-clipboard title="Copy command">
                                <ciam-text class="cli">{{ lb.publicIp }}</ciam-text>
                              </ciam-clipboard>
                            </div>
                          </div>
                          <div class="flex flex-col gap-2">
                            <ciam-text class="bold"> {{ $t('deployment.advanced.gateway') }}</ciam-text>
                            <div>
                              <ciam-clipboard title="Copy command">
                                <ciam-text class="cli"> {{ topology.gateway.publicIp }}</ciam-text>
                              </ciam-clipboard>
                            </div>
                          </div>
                        </div>

                        <div class="flex flex-col gap-2">
                          <ciam-text class="bold">Servers</ciam-text>
                          <div class="flex flex-col gap-2">
                            <div class="flex flex-col gap-2">
                              <ciam-text>Load balancer</ciam-text>
                              <div v-for="lb in topology.loadBalancers" :key="lb.privateIp">
                                <ciam-clipboard title="Copy command" :payload="sshTo(lb.privateIp)">
                                  <ciam-text class="cli">{{ lb.privateIp }}</ciam-text>
                                </ciam-clipboard>
                              </div>
                            </div>
                            <div class="flex flex-col gap-2">
                              <ciam-text>Keycloak</ciam-text>
                              <div v-for="kc in topology.keycloakNodes" :key="kc.privateIp">
                                <ciam-clipboard title="Copy command" :payload="sshTo(kc.privateIp)" >
                                  <ciam-text class="cli">{{ kc.privateIp }}</ciam-text>
                                </ciam-clipboard>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line>
                  <template v-slot:label>
                    {{ $t('deployment.advanced.coldBackup') }}
                  </template>
                  <template v-slot:content>
                    <div class="flex flex-col gap-2">
                      <ciam-link target="_blank" :href="linkToColdBackupBucket()">Access cold backup bucket</ciam-link>
                    </div>
                  </template>
                </ciam-card-content-line>
              </ciam-card-content-lines>
            </ciam-card>
          </template>
        </ciam-card-content-line>
        <ciam-card-content-line type="full-width" v-if="problem">
          <template v-slot:content>
            <ciam-alert :title="problem.title" :description="problem.detail" :type="AlertStatus.ALERT"></ciam-alert>
          </template>
        </ciam-card-content-line>

        <ciam-card-content-line type="full-width">
          <template v-slot:content>
            <ciam-card>
              <ciam-card-header>
                <template v-slot:header>
                  <ciam-text>{{ $t('deployment.advanced.actions') }}</ciam-text>
                </template>
              </ciam-card-header>
              <ciam-card-content-lines>
                <ciam-card-content-line>
                  <template v-slot:label> Set status</template>
                  <template v-slot:content>
                    <div class="flex flex-row gap-2 justify-stretch">
                      <div>
                        <ciam-select :options="status" v-model="form.status"></ciam-select>
                      </div>
                      <div class="w-[500px]">
                        <ciam-button class="primary" @click="setDeploymentStatus()">Set</ciam-button>
                      </div>
                    </div>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line>
                  <template v-slot:label>Restart</template>
                  <template v-slot:content>
                    <div class="flex flex-row gap-2 justify-stretch">
                      <div>
                        <ciam-select :options="redeployActions" v-model="form.redeployMode"></ciam-select>
                      </div>
                      <div class="w-[500px]">
                        <ciam-button class="primary" @click="redeploy()">Redeploy</ciam-button>
                      </div>
                    </div>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line>
                  <template v-slot:label>
                    {{ $t('deployment.advanced.backup.help') }}
                  </template>
                  <template v-slot:content>
                    <ciam-button class="danger" @click="createBackup()">
                      {{ $t('deployment.advanced.backup.button') }}
                      <template v-slot:right>
                        <ciam-icon name="fa-download"></ciam-icon>
                      </template>
                    </ciam-button>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line>
                  <template v-slot:label>
                    {{ $t('deployment.advanced.contact') }}
                  </template>
                  <template v-slot:content>
                    <ciam-button class="danger" @click="contactOrganization()">
                      {{ $t('organization.details.contact.button') }}
                      <template v-slot:right>
                        <ciam-icon name="fa-envelope"></ciam-icon>
                      </template>
                    </ciam-button>
                  </template>
                </ciam-card-content-line>

                <ciam-card-content-line>
                  <template v-slot:label>
                    {{ $t('deployment.advanced.reloadTopology.help') }}
                  </template>
                  <template v-slot:content>
                    <ciam-button class="danger" @click="reloadTopology()">
                      {{ $t('deployment.advanced.reloadTopology.button') }}
                      <template v-slot:right>
                        <ciam-icon name="fa-solid fa-arrow-rotate-right"></ciam-icon>
                      </template>
                    </ciam-button>
                  </template>
                </ciam-card-content-line>

                <ciam-card-content-line>
                  <template v-slot:label>
                    {{ $t('deployment.advanced.finalize.help') }}
                  </template>
                  <template v-slot:content>
                    <ciam-button class="danger" @click="taskFinalize()">
                      {{ $t('deployment.advanced.finalize.button') }}
                      <template v-slot:right>
                        <ciam-icon name="fa-solid fa-chart-line"></ciam-icon>
                      </template>
                    </ciam-button>
                  </template>
                </ciam-card-content-line>

                <ciam-card-content-line>
                  <template v-slot:label>
                    {{ $t('deployment.advanced.taskMetrics.help') }}
                  </template>
                  <template v-slot:content>
                    <ciam-button class="danger" @click="taskMetrics()">
                      {{ $t('deployment.advanced.taskMetrics.button') }}
                      <template v-slot:right>
                        <ciam-icon name="fa-solid fa-chart-line"></ciam-icon>
                      </template>
                    </ciam-button>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line>
                  <template v-slot:label>
                    {{ $t('deployment.advanced.disasterRecovery.help') }}
                  </template>
                  <template v-slot:content>
                    <ciam-button class="danger" @click="parametersModal.isOpened = true">
                      {{ $t('deployment.advanced.disasterRecovery.button') }}
                      <template v-slot:right>
                        <ciam-icon name="fa-solid fa-chart-line"></ciam-icon>
                      </template>
                    </ciam-button>
                  </template>
                </ciam-card-content-line>
              </ciam-card-content-lines>
            </ciam-card>
          </template>
        </ciam-card-content-line>
      </ciam-card-content-lines>
    </ciam-card>

    <b-modal
      :active.sync="parametersModal.isOpened"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <standard-modal :buttons="parametersModal.buttons" :item="deployment" @result="disasterRecovery()">
        <template v-slot:header>{{ $t('deployment.advanced.disasterRecovery.header') }}</template>
        <template v-slot:default>
          <ciam-card>
            <ciam-card-content-lines>
              <ciam-card-content-line>
                <template v-slot:label>{{ $t('deployment.advanced.disasterRecovery.version') }}</template>
                <template v-slot:content>
                  <div class="grid grid-cols-2 gap-2">
                    <ciam-input
                      type="text"
                      v-model="parametersModal.parameters.version"
                      placeholder="Keycloak Version"
                    />
                  </div>
                </template>
              </ciam-card-content-line>
              <ciam-card-content-line>
                <template v-slot:label>{{ $t('deployment.advanced.disasterRecovery.provider') }}</template>
                <template v-slot:content>
                  <div class="grid grid-cols-2 gap-2">
                    <ciam-input
                      type="text"
                      v-model="parametersModal.parameters.provider"
                      placeholder="Cloud Provider"
                    />
                  </div>
                </template>
              </ciam-card-content-line>
              <ciam-card-content-line>
                <template v-slot:label>{{ $t('deployment.advanced.disasterRecovery.region') }}</template>
                <template v-slot:content>
                  <div class="grid grid-cols-2 gap-2">
                    <ciam-input type="text" v-model="parametersModal.parameters.region" placeholder="Region" />
                  </div>
                </template>
              </ciam-card-content-line>
              <ciam-card-content-line>
                <template v-slot:label>{{
                  $t('deployment.advanced.disasterRecovery.triggerLastMinuteBackup')
                }}</template>
                <template v-slot:content>
                  <div class="grid grid-cols-2 gap-2">
                    <ciam-checkbox v-model="parametersModal.parameters.bootstrap.triggerLastMinuteBackup" />
                  </div>
                </template>
              </ciam-card-content-line>
              <ciam-card-content-line>
                <template v-slot:label>{{ $t('deployment.advanced.disasterRecovery.pipelineBranch') }}</template>
                <template v-slot:content>
                  <div class="grid grid-cols-2 gap-2">
                    <ciam-input
                      type="text"
                      v-model="parametersModal.parameters.pipelineBranch"
                      placeholder="Pipeline branch"
                    />
                  </div>
                </template>
              </ciam-card-content-line>
              <ciam-card-content-line>
                <template v-slot:label>{{
                  $t('deployment.advanced.disasterRecovery.setLiveAutomaticallyOnSuccess')
                }}</template>
                <template v-slot:content>
                  <div class="grid grid-cols-2 gap-2">
                    <ciam-checkbox v-model="parametersModal.parameters.onSuccess.setLive" />
                  </div>
                </template>
              </ciam-card-content-line>
            </ciam-card-content-lines>
          </ciam-card>
        </template>
      </standard-modal>
    </b-modal>
  </div>
</template>

<script>
import DeploymentService from '@/pages/deployments/DeploymentService';
import { Notification } from 'vue-notifyjs';
import CiamLink from '@/components/CiamLink';
import StandardModal from '@/components/StandardModal';
import i18n from '@/i18n';
import OrganizationService from '@/pages/organizations/OrganizationService';
import CiamClipboard from '@/components/CiamClipboard.vue';
import CiamSelect from '@/components/CiamSelect.vue';
import CiamInput from '@/components/CiamInput';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert.vue';
import CiamCheckbox from '@/components/themes_templates/CiamCheckbox.vue';

export default {
  name: 'DeploymentConfigurations',
  components: {
    CiamCheckbox,
    CiamSelect,
    CiamClipboard,
    CiamLink,
    StandardModal,
    CiamInput,
    CiamAlert,
  },
  data() {
    return {
      credentialsUser: null,
      credentialsPassword: null,
      problem: null,
      AlertStatus: AlertStatus,
      deployment: null,
      instanceFullName: null,
      topology: null,
      usage: null,
      form: {
        status: 'RUNNING',
        redeployMode: 'complete',
      },
      parametersModal: {
        isOpened: false,
        parameters: {
          version: null,
          provider: null,
          region: null,
          bootstrap: {
            triggerLastMinuteBackup: false,
          },
          onSuccess: {
            setLive: false,
          },
          pipelineBranch: null,
        },
        buttons: [
          { text: this.$t('actions.cancel') },
          {
            text: this.$t('actions.save'),
            classes: ['is-info', 'is-outlined'],
            returnVal: true,
          },
        ],
      },
    };
  },
  mounted() {
    DeploymentService.get(this.$route.params.id)
      .then((deployment) => (this.deployment = deployment))
      .then((deployment) =>
        DeploymentService.getUsage(deployment)
          .then((response) => (this.usage = response))
          .catch((err) => (this.problem = err))
      );
    DeploymentService.getTopology(this.$route.params.id)
      .then((topology) => (this.topology = topology))
      .catch((reason) => console.error('topology could not be loaded', reason));
    this.getInstances(this.$route.params.id);
  },
  methods: {
    reveal() {
      DeploymentService.getCredentials(this.deployment.id).then((credentials) => {
        this.credentialsUser = credentials.username;
        this.credentialsPassword = credentials.password;
      });
    },
    createBackup() {
      DeploymentService.createBackup(this.deployment.id).then(
        () => {
          Notification.notify({
            message: 'Back up has started ...',
            type: 'info',
          });
        },
        (err) => {
          console.error(err);
          Notification.notify({
            message: 'Could not create back up:',
            type: 'danger',
          });
        }
      );
    },
    async getInstances(deploymentId) {
      const instances = await DeploymentService.getInstances(deploymentId);
      const liveInstance = await DeploymentService.getInstance(instances.liveInstanceId);
      this.instanceFullName = liveInstance.fullName;
    },
    disasterRecovery() {
      if (!confirm('Are you sure you want to duplicate the instance ?')) {
        return;
      }
      Object.keys(this.parametersModal.parameters).forEach((k) =>
        !this.parametersModal.parameters[k] ? delete this.parametersModal.parameters[k] : null
      );
      DeploymentService.disasterRecovery(this.deployment.id, this.parametersModal.parameters).then(
        () => {
          Notification.notify({
            message: 'Duplication has started ...',
            type: 'info',
          });
        },
        (err) => {
          console.error(err);
          Notification.notify({
            message: 'Could not duplicate:' + err,
            type: 'danger',
          });
        }
      );
    },
    setDeploymentStatus() {
      DeploymentService.setStatus(this.deploymentId, this.form.status).then(
        () => {
          Notification.notify({
            message: `Status forced to ${this.form.status}`,
            type: 'info',
          });
        },
        (err) => {
          console.error(err);
          Notification.notify({
            message: 'Could not set status:' + err,
            type: 'danger',
          });
        }
      );
    },
    sshTo(privateIp, name) {
      return (
        'ssh ubuntu@' +
        privateIp +
        ' -p 2224 -J bastion@bastion.tooling.cloud-iam.com:2224,bastion@' +
        this.topology.loadBalancers[0].publicIp +
        ':2224'
      );
    },
    contactOrganization() {
      const subject = prompt(i18n.t('organization.details.contact.prompt'), `[Cloud-IAM - ${this.deployment.name}] `);
      if (subject === null) {
        return;
      }

      OrganizationService.contactOrganization(this.deployment.organizationId, subject).then((url) =>
        window.open(url, '_blank').focus()
      );
    },

    linkToDashboardMetrics() {
      if (this.deployment !== null) {
        return `https://grafana.observability.cloud-iam.com/d/n9TmwXY4k/instance-overall-21-x?orgId=1&var-deployment=${this.deployment.name}&var-kcInstance=All&var-lbInstance=All&var-instance=All&from=now-1h&to=now`;
      } else {
        return '';
      }
    },
    linkToDashboardLogs() {
      if (this.deployment !== null) {
        return `https://grafana.observability.cloud-iam.com/d/liz0yRCZz/logs-dashboard?var-deployment=${this.instanceFullName}&var-host=All&var-job=All&var-search=&from=now-1h&to=now`;
      } else {
        return '';
      }
    },
    linkToDashboardAccessLogs() {
      if (this.deployment !== null) {
        return `https://grafana.observability.cloud-iam.com/d/7nOWJZy7k/nginx-access-logs?orgId=1&var-deployment=${this.instanceFullName}&var-host=All&var-search=&from=now-1h&to=now`;
      } else {
        return '';
      }
    },

    linkToColdBackupBucket() {
      if (this.deployment !== null) {
        return `https://console.scaleway.com/object-storage/buckets/fr-par/cold-backups/explorer/${this.deployment.name}/`;
      } else {
        return '';
      }
    },

    redeploy() {
      if (!confirm('Are you sure you want to redeploy the cluster in a ' + this.form.redeployMode + ' fashion?')) {
        return;
      }
      DeploymentService.redeploy(this.$route.params.id, this.form.redeployMode).then(
        () => {
          Notification.notify({
            message: 'Redeploy has started (' + this.form.redeployMode + ')...',
            type: 'info',
          });
        },
        (err) => {
          console.error(err);
          Notification.notify({
            message: 'Could not redeploy:' + err,
            type: 'danger',
          });
        }
      );
    },
    reloadTopology() {
      if (!confirm('Are you sure you want to RELOAD topology of the cluster?')) {
        return;
      }
      DeploymentService.reloadTopology(this.$route.params.id).then(
        () => {
          Notification.notify({
            message: 'Reload topology started...',
            type: 'info',
          });
        },
        (err) => {
          console.error(err);
          Notification.notify({
            message: 'An error occurred:' + err,
            type: 'danger',
          });
        }
      );
    },

    // internal tooling, no need for i18n
    taskMetrics() {
      DeploymentService.metricConfiguration(this.$route.params.id).then(
        () => {
          Notification.notify({
            message: 'Metric configuration started...',
            type: 'info',
          });
        },
        (err) => {
          console.error(err);
          Notification.notify({
            message: 'An error ocurred:' + err,
            type: 'danger',
          });
        }
      );
    },
    taskFinalize() {
      DeploymentService.finalizeConfiguration(this.$route.params.id).then(
        () => {
          Notification.notify({
            message: 'Finalizing...',
            type: 'info',
          });
        },
        (err) => {
          console.error(err);
          Notification.notify({
            message: 'An error ocurred:' + err,
            type: 'danger',
          });
        }
      );
    },
  },
  computed: {
    /**
     *
     * @returns {String}
     */
    deploymentId() {
      return this.deployment.id;
    },
    redeployActions() {
      return this.$t(`redeployActions`);
    },
    status() {
      return this.$t(`status`);
    },
  },
};
</script>

<style scoped>
.cli-container {
  margin-bottom: 1rem;
}

.cli {
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #eeeeee;
  padding: 0.2rem;
  margin: 0.2rem;
  font-family: monospace;
  white-space: nowrap;
}

.credentials {
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #eeeeee;
  padding: 0.2rem;
  font-family: monospace;
  vertical-align: middle;
}
</style>